// Import icons from different libraries
import { MdOutlineAttachEmail, MdOutlineContactPhone } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { SlSocialLinkedin } from "react-icons/sl";
import { IoIosNotifications } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import { SiGoogleadsense } from "react-icons/si";

const useIcons = {
    email: MdOutlineAttachEmail,
    phone: MdOutlineContactPhone,
    location: GrMapLocation,
    linkedin: SlSocialLinkedin,
    notification:IoIosNotifications,
    search:GoSearch,
    googleAds: SiGoogleadsense
};

export default useIcons;
