import React from "react";
import "./Home.css";
import useIcons from "../../components/icons/icons";
import Table from "../../components/table/Table";

const Home = () => {
  return (
    <div className="home-container container">
      <div className="hillgro-hero">
        <h1>HillGro</h1>
        <h4>AgriTech & Development Pvt. Ltd.</h4>
        <div className="comment">
          Our Engineers are working hard bring you something amazing. Stay tuned!
        </div>
      </div>
    </div>
  );
};

export default Home;
