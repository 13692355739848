import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { Toaster } from "react-hot-toast";
import { useContext } from "react";
import { Context } from ".";
import "./App.css";
import Header from "./components/header/Header";
import Home from "./pages/home/Home.jsx";

function App() {
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useContext(Context);

  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* <Header style={{ zIndex: 1 }} /> */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </main>
      </div>
      <Toaster position="bottom-right" reverseOrder={true} />
    </Router>
  );
}


export default App;
